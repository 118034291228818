exports.components = {
  "component---src-templates-404-jsx": () => import("./../../../src/templates/404.jsx" /* webpackChunkName: "component---src-templates-404-jsx" */),
  "component---src-templates-account-addresses-jsx": () => import("./../../../src/templates/account/addresses.jsx" /* webpackChunkName: "component---src-templates-account-addresses-jsx" */),
  "component---src-templates-account-index-jsx": () => import("./../../../src/templates/account/index.jsx" /* webpackChunkName: "component---src-templates-account-index-jsx" */),
  "component---src-templates-account-login-jsx": () => import("./../../../src/templates/account/login.jsx" /* webpackChunkName: "component---src-templates-account-login-jsx" */),
  "component---src-templates-account-register-jsx": () => import("./../../../src/templates/account/register.jsx" /* webpackChunkName: "component---src-templates-account-register-jsx" */),
  "component---src-templates-blog-category-jsx": () => import("./../../../src/templates/blogCategory.jsx" /* webpackChunkName: "component---src-templates-blog-category-jsx" */),
  "component---src-templates-blog-index-jsx": () => import("./../../../src/templates/blogIndex.jsx" /* webpackChunkName: "component---src-templates-blog-index-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blogPost.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-cart-jsx": () => import("./../../../src/templates/cart.jsx" /* webpackChunkName: "component---src-templates-cart-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-product-jsx": () => import("./../../../src/templates/product.jsx" /* webpackChunkName: "component---src-templates-product-jsx" */),
  "component---src-templates-search-jsx": () => import("./../../../src/templates/search.jsx" /* webpackChunkName: "component---src-templates-search-jsx" */),
  "component---src-templates-taxon-jsx": () => import("./../../../src/templates/taxon.jsx" /* webpackChunkName: "component---src-templates-taxon-jsx" */)
}

